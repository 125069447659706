.react-datepicker-wrapper {
    width: 50%;
    color: #000;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    list-style: none;
}

li.page-item.active {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}